import React, { useContext, useEffect, useState } from 'react'
import useSocket from '../../../Websoket.js';
import { Link } from 'react-router-dom';
import { PopupContext } from '../../LoginPopup/LoginPopupContext.js';
import betslipclosearrow from '../../../assets/images/betslip-close-arrow.png'
import FancyBookPopup from './FancyBookPopup.js';

function FancyOddEven({ eventAccess, categorizedData, getfancyClick, receivedBetlistData }) {
    const FancyOddEven = {
        "eventId": eventAccess?.matchData?.eventId,
        "userId": "66604ec15be7e5de0f25ac4d"
    };

    const isOneClicked = sessionStorage.getItem('isOneClicked')
    let oneClickValue
    if (isOneClicked == 'true') {
        oneClickValue = sessionStorage.getItem('oneClickAmount')
    }

    const onClickFunction = (runnerId, toggleclass, event, runnerName, oddItem) => {
        event.persist();
        setBetLoader(true);
        setSlideToggle(slideToggle === runnerId ? runnerId : runnerId);
        setSecIds(runnerId);
        setBklyClass(toggleclass);
        const strongElement = event.target.tagName === 'SPAN'
            ? event.target.querySelector('strong').innerText
            : event.target.closest('span')?.querySelector('strong').innerText;
        setoddsValue(strongElement);
        setPriceGet(oneClickValue)
        getfancyClick({ eventid: receivedFancyOddEven?.finalResult?.[0]?.eventId, marketId: runnerId, stake: oneClickValue * 1, bklyClassValue: toggleclass, selectionName: runnerName, rate: oddsValue, odds: bklyClassValue === 'back' ? oddItem?.yesRate : oddItem?.noRate })
    }

    const [fancyOddEvenData, setFancyOddEvenData] = useState(FancyOddEven);
    const { isConnected, receivedFancyOddEven, sendMessage, receivedBookFancy } = useSocket();
    const [slideToggle, setSlideToggle] = useState(false)
    const [priceGet, setPriceGet] = useState(0);
    const [oddsValue, setoddsValue] = useState();
    const [bklyClassValue, setBklyClass] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
    const { openPopup, loggedIn, isLoggedOut, direactsecId, setSecIds, clearSecId,isBetLoader, loaderCounter } = useContext(PopupContext);
    const [plusMinusValue, setplusMinusValue] = useState();
    const [fancyBookPopup, setFancyBookPopup] = useState();
    const [Beton, setBeton] = useState();
    const [betLoader, setBetLoader] = useState(false);
    const convertToOriginalValue = (shorthand) => {
        // Ensure shorthand is a string
        if (typeof shorthand !== 'string') {
            return shorthand; // Return the value as is if it's not a string
        }
    
        const value = parseFloat(shorthand); // Extract numeric part
        if (shorthand.toUpperCase().endsWith('K')) {
            return value * 1_000; // Multiply by 1,000 for 'K'
        } else if (shorthand.toUpperCase().endsWith('M')) {
            return value * 1_000_000; // Multiply by 1,000,000 for 'M'
        }
        return value; // Return the numeric value if no suffix
    };

const allValue = () => {
    let userinfo = sessionStorage.getItem('userinfo');
    if (userinfo !== null) {
        let data = JSON.parse(userinfo);
        return data.availableBalance - data.exposure;
    }else{
        return 0;
    }
};
    useEffect(() => {
        if(!isBetLoader){
            handlecloseSlip();
        }
      }, [isBetLoader, loaderCounter]);

    useEffect(() => {
        let betLoderInterval;
        betLoderInterval = setInterval(() => {
            setBetLoader(false);
        }, 1500);
        return () => {
            if (betLoderInterval) {
                clearInterval(betLoderInterval);
            }
        };
    }, []);

    const handleShow = (marketId) => {
        setFancyBookPopup(true)
        let userinfo = sessionStorage.getItem('userinfo');
        if (userinfo && marketId) {
            userinfo = JSON.parse(userinfo);
            sendMessage('getFancyBookDATAuserSide', { id: marketId, user: userinfo });
        }
    }
    const handleClose = () => {
        setFancyBookPopup(false)
    }

    const addAllValue = (newValue) => {
        setplusMinusValue(newValue);
            setPriceGet(newValue);
    };

    const addValue = (newValue) => {
        setplusMinusValue(newValue)
        if (priceGet !== undefined) {
            setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
        } else {
            setPriceGet(newValue);
        }
    };

    const PlusValue = () => {
        const newValue = plusMinusValue ? plusMinusValue : 100


        if (priceGet !== undefined) {
            setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
        } else {
            setPriceGet(newValue);
        }
    };

    const subtractValue = () => {
        const valueToSubtract = plusMinusValue ? plusMinusValue : 100
        setPriceGet(prevPrice => {
            const currentPrice = prevPrice ? Number(prevPrice) : 0;
            const newPrice = currentPrice - valueToSubtract;
            return newPrice < 0 ? 0 : newPrice;
        });
    };

    const incrementValue = (increment) => {
        setPriceGet(prevPrice => {
            const currentPrice = prevPrice ? Number(prevPrice) : 0;
            return currentPrice + increment;
        });
    };

    const removeLastDigit = () => {
        setPriceGet(prevPrice => {
            if (prevPrice !== undefined) {
                const newPrice = String(prevPrice).slice(0, -1);
                return newPrice === '' ? 0 : Number(newPrice);
            }
            return 0;
        });
    };

    useEffect(() => {
        let intervalId;
        if (isConnected && fancyOddEvenData) {
            sendMessage('oddEvenMarketIds', fancyOddEvenData);
            intervalId = setInterval(() => {
                sendMessage('oddEvenMarketIds', fancyOddEvenData);
            }, 500);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isConnected, fancyOddEvenData, sendMessage, direactsecId]);

    const toggleHandle = (slideId, bklyClass, event, runnerName) => {
        const strongElement = event.target.tagName === 'SPAN'
            ? event.target.querySelector('strong').innerText
            : event.target.closest('span')?.querySelector('strong').innerText;
        // console.log('spanText spanText spanText', strongElement);
        setSlideToggle(slideToggle === slideId ? slideId : slideId);
        setBklyClass(bklyClass);
        setoddsValue(strongElement);
        setSecIds(slideId);
        setBeton(runnerName)
    }

    const handlecloseSlip = () => {
        setSlideToggle(false);
        clearSecId();
        setPriceGet(0)
    }

    const getBetSlipClasss = (oddItem) => {
        if (direactsecId === oddItem.marketId) {
            if(oddItem.status !== 'OPEN'){
                clearSecId()
                return 'betslip-wraper'
            }else{
                return 'betslip-wraper open'
            }
        }
        return 'betslip-wraper';
      };

    return (
        <>
            {eventAccess?.oddeventStatus ? <>
                <div className='heading match-heading'>
                    <div className='heading-text sport-name'> Odd/EVEN </div>
                    <div className='odds-heading col2-odds-heading'>
                        <div className='heading-text box'>No</div>
                        <div className='heading-text box'>Yes</div>
                    </div>
                </div>
                <div className='match-data'>
                    <div className='match-list'>
                        {categorizedData.length === 0 ?
                            <>
                                {receivedFancyOddEven?.finalResult?.map((oddItem, index) => (
                                    <div className='data-with-betslip' key={index}>
                                        <div className='match-info-row'>
                                            <div className='match-name'>{oddItem.marketName}
                                                {
                                                    receivedBetlistData && receivedBetlistData.bets && receivedBetlistData.bets.filter(item => item.marketId === oddItem.marketId).length > 0 ? (
                                                        <button className="btn fancy-book-btn" onClick={() => handleShow(oddItem.marketId)}>Book</button>
                                                    ) : null
                                                }
                                            </div>
                                            <div className='market-limit'>
                                                <span>Min : {oddItem?.min_stake}, </span>
                                                <span>Max : {oddItem?.max_stake}</span>
                                            </div>
                                            <div className='match-odds'>
                                            {oddItem.resumesuspendstatus ? (
                                                oddItem?.status !== 'OPEN' ? (
                                                direactsecId === oddItem.marketId ? null : <div className='suspended'>{oddItem?.status}</div>
                                            ) : ''
                                            ) : (<div className='suspended'>suspended</div>)}
                                                <div className='box'>
                                                    <span className='pink' onClick={(event) => { 
                                                     if(!isBetLoader){
                                                    if (isOneClicked === "true") { 
                                                        onClickFunction(oddItem.marketId, 'lay', event, oddItem.marketName, oddItem);
                                                    } else { 
                                                        toggleHandle(oddItem.marketId, 'lay', event, oddItem.marketName); 
                                                    }
                                                     }
                                                     }}>
                                                    {oddItem?.status === 'OPEN' ? (
                                                    <><strong>{oddItem?.noValue}</strong> <small>{oddItem?.noRate}</small></>
                                                    ) : (
                                                        <i className="fa fa-lock"></i>
                                                    )}
                                                    </span>
                                                </div>
                                                <div className='box'>
                                                    <span className='blue' onClick={(event) => { 
                                                        if(!isBetLoader){
                                                            if (isOneClicked === "true") { 
                                                                onClickFunction(oddItem.marketId, 'back', event, oddItem.marketName, oddItem); 
                                                            } else { 
                                                                toggleHandle(oddItem.marketId, 'back', event, oddItem.marketName); 
                                                            }
                                                        } }}>
                                                    {oddItem?.status === 'OPEN' ? (
                                                        <><strong>{oddItem?.yesValue}</strong> <small>{oddItem?.yesRate}</small></>
                                                    ) : (
                                                        <i className="fa fa-lock"></i>
                                                    )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={getBetSlipClasss(oddItem)}>
                                            <div className={`betslip ${direactsecId === oddItem.marketId ? 'active' : ''} ${bklyClassValue}`}>

                                                <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                                <div className="betslip-top">
                                                    <div className="bet-selection-name" >Bet on : {Beton} @{oddsValue}</div>
                                                    <div className="bet-profit"></div>
                                                    <div className="betslip-odds"> <strong>Odds</strong> <span className="blue-text">{oddsValue}</span> </div>
                                                    <div className="betslip-stake">
                                                        <strong>Stake</strong>
                                                        <div className="input-group">
                                                            <span className="minus" onClick={() => {
                                                                setPriceGet((prevPrice) => (Number(prevPrice) >= 100 ? Number(prevPrice) - 100 : 0));
                                                            }}>-</span>
                                                            <input type="text" value={priceGet} className="set-stake-form-input" onChange={(e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPriceGet(value);
    }
  }} placeholder="00" aria-label="Last name"
                                                                autofocus="" maxLength="8" />
                                                            <span className="plus" onClick={() => { setPriceGet((prevPrice) => Number(prevPrice) + 100) }}>+</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="betslip-placebutton">
                                                    <div className="price-btns">
                                                        <span id="100" onClick={() => addValue('100')}>+100</span>
                                                        <span id="500" onClick={() => addValue('500')}>+500</span>
                                                        <span id="1000" onClick={() => addValue('1000')}>+1k</span>
                                                        <span id="10000" onClick={() => addValue('10000')}>+10k</span>
                                                        <span id="50000" onClick={() => addValue('50000')}>+50k</span>
                                                        <span id="100000" onClick={() => addValue('100000')}>+100k</span>
                                                        <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${convertToOriginalValue(receivedFancyOddEven?.finalResult?.find(item => item.marketName === oddItem.marketName)?.max_stake)}`,event)}>MAX</span>
                                                        <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${allValue()}`,event)}>All</span>
                                                    </div>
                                                    <div className="betplace-btn">
                                                        {loggedIn || isLoggedIn ? <button className="place-bet" onClick={() => {
                                                            {
                                                                setBetLoader(true);
                                                                getfancyClick({ eventid: receivedFancyOddEven?.finalResult?.[0]?.eventId, marketId: oddItem.marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.marketName, rate: oddsValue, odds: bklyClassValue === 'back' ? oddItem?.yesRate : oddItem?.noRate })
                                                            }
                                                        }}>Place Bet</button> : <button onClick={openPopup} className="login-btn">Login to continue</button>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`betslip_mobileview ${direactsecId === oddItem.marketId ? 'active' : ''} ${bklyClassValue}`}>
                                                <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                                <table className="eventdetails">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className='plus-minus-wraper'>
                                                                    <div className='plus-minus'>
                                                                        <span>-</span>
                                                                        <input type="text" value={oddsValue} />
                                                                        <span>+</span>
                                                                    </div>
                                                                    <div className='plus-minus'>
                                                                        <span onClick={() => subtractValue()}>-</span>
                                                                        <input type="text" value={priceGet} className="set-stake-form-input" onChange={(e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPriceGet(value);
    }
  }} placeholder="00" aria-label="Last name" autofocus="" maxLength="8" />
                                                                        <span onClick={() => PlusValue()} >+</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="11" className='mbet-slip'>
                                                                <div className="mbet-slip-wraper">
                                                                    <div className="odds-value" onClick={() => addValue('100')} id="100">+100</div>
                                                                    <div className="odds-value" onClick={() => addValue('500')} id="500">+500</div>
                                                                    <div className="odds-value" onClick={() => addValue('1000')} id="1000">+1k</div>
                                                                    <div className="odds-value" onClick={() => addValue('10000')} id="10000">+10k</div>
                                                                    <div className="odds-value" onClick={() => addValue('50000')} id="50000">+50k</div>
                                                                    <div className="odds-value max" onClick={(event)=>addAllValue(`${convertToOriginalValue(receivedFancyOddEven?.finalResult?.find(item => item.marketName === oddItem.marketName)?.max_stake)}`,event)} id="10000">MAX</div>
                                                                    <div className="odds-value all" onClick={(event)=>addAllValue(`${allValue()}`,event)} id="98941.80">ALL IN</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="numbering">
                                                            <td colspan="11">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><span onClick={() => incrementValue('1')} id="1">1</span></td>
                                                                            <td><span onClick={() => incrementValue('2')} id="2">2</span></td>
                                                                            <td><span onClick={() => incrementValue('3')} id="3">3</span></td>
                                                                            <td><span onClick={() => incrementValue('4')} id="4">4</span></td>
                                                                            <td><span onClick={() => incrementValue('5')} id="5">5</span></td>
                                                                            <td><span onClick={() => incrementValue('6')} id="6">6</span></td>
                                                                            <td rowspan="2"><span className="back-span" onClick={() => removeLastDigit()}><img alt="" src={betslipclosearrow} /></span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><span onClick={() => incrementValue('7')} id="7">7</span></td>
                                                                            <td><span onClick={() => incrementValue('8')} id="8">8</span></td>
                                                                            <td><span onClick={() => incrementValue('9')} id="9">9</span></td>
                                                                            <td><span onClick={() => incrementValue('0')} id="0">0</span></td>
                                                                            <td><span onClick={() => incrementValue('00')} id="double0">00</span></td>
                                                                            <td><span onClick={() => incrementValue('000')} id="dot">000</span></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr className="bet-btns">
                                                            <td colspan="11">
                                                                <div className="btn-wraper">
                                                                    <button className="btn cancel-bet">CANCEL</button>
                                                                    {loggedIn || isLoggedIn ? <button className="btn place-bet" onClick={() => {
                                                                        {
                                                                            setBetLoader(true);
                                                                            getfancyClick({ eventid: receivedFancyOddEven?.finalResult?.[0]?.eventId, marketId: oddItem.marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.marketName, rate: oddsValue, odds: bklyClassValue === 'back' ? oddItem?.yesRate : oddItem?.noRate })
                                                                        }
                                                                    }}>Place Bet</button> : <button onClick={openPopup} className="btn place-bet">Login to continue</button>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {isBetLoader ?
                                                <div className="loader-sec loading">
                                                    Placing Your Bet, Please Wait <div className='loader-box'><span className='loading-number'>{String(loaderCounter).padStart(2, '0')}</span><span className='loader'></span></div>
                                                </div> : ''}
                                        </div>

                                    </div>
                                ))}
                            </>
                            :
                            <>
                                {categorizedData.map((oddItem, index) => (
                                    <div className='data-with-betslip' key={index}>
                                        <div to='' className='match-info-row'>
                                            <div className='match-name'>{oddItem.marketName}
                                                {
                                                    receivedBetlistData && receivedBetlistData.bets && receivedBetlistData.bets.filter(item => item.marketId === oddItem.marketId).length > 0 ? (
                                                        <button className="btn fancy-book-btn" onClick={() => handleShow(oddItem.marketId)}>Book</button>
                                                    ) : null
                                                }
                                            </div>
                                            <div className='market-limit'>
                                                <span>Min : {receivedFancyOddEven?.finalResult?.find(item => item.marketName === oddItem.marketName)?.min_stake}, </span>
                                                <span>Max : {receivedFancyOddEven?.finalResult?.find(item => item.marketName === oddItem.marketName)?.max_stake}</span>
                                            </div>
                                            <div className='match-odds'>
                                            {receivedFancyOddEven?.finalResult?.find(item => item.marketName === oddItem.marketName)?.resumesuspendstatus ? (
                                                oddItem?.status !== 'OPEN' ? (
                                                direactsecId === oddItem.marketId ? '' : <div className='suspended'>{oddItem?.status}</div>
                                            ) : ''
                                            ) : (<div className='suspended'>suspended</div>)}
                                                <div className='box'>
                                                    <span className='pink' onClick={(event) => { if(!isBetLoader){if (isOneClicked === "true") { onClickFunction(oddItem.marketId, 'lay', event, oddItem.marketName, oddItem); } else { toggleHandle(oddItem.marketId, 'lay', event, oddItem.marketName); }} }}>{oddItem ? <><strong>{oddItem?.noValue}</strong> <small>{oddItem?.noRate}</small></> : <i className="fa fa-lock"></i>}</span>
                                                </div>
                                                <div className='box'>
                                                    <span className='blue' onClick={(event) => { if(!isBetLoader){if (isOneClicked === "true") { onClickFunction(oddItem.marketId, 'back', event, oddItem.marketName, oddItem); } else { toggleHandle(oddItem.marketId, 'back', event, oddItem.marketName); }} }}>{oddItem ? <><strong>{oddItem?.yesValue}</strong> <small>{oddItem?.yesRate}</small></> : <i className="fa fa-lock"></i>}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={getBetSlipClasss(oddItem)}>
                                            <div className={`betslip ${direactsecId ? 'active' : ''} ${bklyClassValue}`}>

                                                <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                                <div className="betslip-top">
                                                    <div className="bet-selection-name" >Bet on : {Beton} @{oddsValue}</div>
                                                    <div className="bet-profit"></div>
                                                    <div className="betslip-odds"> <strong>Odds</strong> <span className="blue-text">{oddsValue}</span> </div>
                                                    <div className="betslip-stake">
                                                        <strong>Stake</strong>
                                                        <div className="input-group">
                                                            <span className="minus" onClick={() => {
                                                                setPriceGet((prevPrice) => (Number(prevPrice) >= 100 ? Number(prevPrice) - 100 : 0));
                                                            }}>-</span>
                                                            <input type="text" value={priceGet} className="set-stake-form-input" onChange={(e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPriceGet(value);
    }
  }} placeholder="00" aria-label="Last name"
                                                                autofocus="" maxLength="8" />
                                                            <span className="plus" onClick={() => { setPriceGet((prevPrice) => Number(prevPrice) + 100) }}>+</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="betslip-placebutton">
                                                    <div className="price-btns">
                                                        <span id="100" onClick={() => addValue('100')}>+100</span>
                                                        <span id="500" onClick={() => addValue('500')}>+500</span>
                                                        <span id="1000" onClick={() => addValue('1000')}>+1k</span>
                                                        <span id="10000" onClick={() => addValue('10000')}>+10k</span>
                                                        <span id="50000" onClick={() => addValue('50000')}>+50k</span>
                                                        <span id="100000" onClick={() => addValue('100000')}>+100k</span>
                                                        <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${convertToOriginalValue(receivedFancyOddEven?.finalResult?.find(item => item.marketName === oddItem.marketName)?.max_stake)}`,event)}>MAX</span>
                                                        <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${allValue()}`,event)}>All</span>
                                                    </div>
                                                    <div className="betplace-btn">
                                                        {loggedIn || isLoggedIn ? <button className="place-bet" onClick={() => {
                                                            {
                                                                setBetLoader(true);
                                                                getfancyClick({ eventid: receivedFancyOddEven?.finalResult?.[0]?.eventId, marketId: oddItem.marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.marketName, rate: oddsValue, odds: bklyClassValue === 'back' ? oddItem?.yesRate : oddItem?.noRate })
                                                            }
                                                        }}>Place Bet</button> : <button onClick={openPopup} className="login-btn">Login to continue</button>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`betslip_mobileview ${direactsecId ? 'active' : ''} ${bklyClassValue}`}>
                                                <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                                <table className="eventdetails">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className='plus-minus-wraper'>
                                                                    <div className='plus-minus'>
                                                                        <span>-</span>
                                                                        <input type="text" value={oddsValue} />
                                                                        <span>+</span>
                                                                    </div>
                                                                    <div className='plus-minus'>
                                                                        <span onClick={() => subtractValue()}>-</span>
                                                                        <input type="text" value={priceGet} className="set-stake-form-input" onChange={(e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPriceGet(value);
    }
  }} placeholder="00" aria-label="Last name" autofocus="" maxLength="8" />
                                                                        <span onClick={() => PlusValue()} >+</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="11" className='mbet-slip'>
                                                                <div className="mbet-slip-wraper">
                                                                    <div className="odds-value" onClick={() => addValue('100')} id="100">+100</div>
                                                                    <div className="odds-value" onClick={() => addValue('500')} id="500">+500</div>
                                                                    <div className="odds-value" onClick={() => addValue('1000')} id="1000">+1k</div>
                                                                    <div className="odds-value" onClick={() => addValue('10000')} id="10000">+10k</div>
                                                                    <div className="odds-value" onClick={() => addValue('50000')} id="50000">+50k</div>
                                                                    <div className="odds-value max" onClick={(event)=>addAllValue(`${convertToOriginalValue(receivedFancyOddEven?.finalResult?.find(item => item.marketName === oddItem.marketName)?.max_stake)}`,event)} id="10000">MAX</div>
                                                                    <div className="odds-value all" onClick={(event)=>addAllValue(`${allValue()}`,event)} id="98941.80">ALL IN</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="numbering">
                                                            <td colspan="11">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><span onClick={() => incrementValue('1')} id="1">1</span></td>
                                                                            <td><span onClick={() => incrementValue('2')} id="2">2</span></td>
                                                                            <td><span onClick={() => incrementValue('3')} id="3">3</span></td>
                                                                            <td><span onClick={() => incrementValue('4')} id="4">4</span></td>
                                                                            <td><span onClick={() => incrementValue('5')} id="5">5</span></td>
                                                                            <td><span onClick={() => incrementValue('6')} id="6">6</span></td>
                                                                            <td rowspan="2"><span className="back-span" onClick={() => removeLastDigit()}><img alt="" src={betslipclosearrow} /></span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><span onClick={() => incrementValue('7')} id="7">7</span></td>
                                                                            <td><span onClick={() => incrementValue('8')} id="8">8</span></td>
                                                                            <td><span onClick={() => incrementValue('9')} id="9">9</span></td>
                                                                            <td><span onClick={() => incrementValue('0')} id="0">0</span></td>
                                                                            <td><span onClick={() => incrementValue('00')} id="double0">00</span></td>
                                                                            <td><span onClick={() => incrementValue('000')} id="dot">000</span></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr className="bet-btns">
                                                            <td colspan="11">
                                                                <div className="btn-wraper">
                                                                    <button className="btn cancel-bet">CANCEL</button>
                                                                    {loggedIn || isLoggedIn ? <button className="btn place-bet" onClick={() => {
                                                                        {
                                                                            setBetLoader(true);
                                                                            getfancyClick({ eventid: receivedFancyOddEven?.finalResult?.[0]?.eventId, marketId: oddItem.marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.marketName, rate: oddsValue, odds: bklyClassValue === 'back' ? oddItem?.yesRate : oddItem?.noRate })
                                                                        }
                                                                    }}>Place Bet</button> : <button onClick={openPopup} className="btn place-bet">Login to continue</button>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {isBetLoader ?
                                                <div className="loader-sec loading">
                                                    Placing Your Bet, Please Wait <div className='loader-box'><span className='loading-number'>{String(loaderCounter).padStart(2, '0')}</span><span className='loader'></span></div>
                                                </div> : ''}
                                        </div>
                                    </div>
                                ))}
                            </>
                        }

                    </div>
                    <FancyBookPopup isopen={fancyBookPopup} handleClose={handleClose} receivedBookFancy={receivedBookFancy} />
                </div>
            </> : ''}
        </>
    )
}

export default FancyOddEven