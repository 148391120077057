import React, { useEffect, useRef, useState } from 'react'
import './scoreboard.css'
import MobileLiveStreamTab from './MobileLiveStreamTab';

function Scoreboard({scoreboardData, receivedBetlistData,eventId}) {
  const spId = scoreboardData?.sportId;
  const evId = scoreboardData?.eventId;
  const [eventName, setEventName] = useState("");
  const [competitionName, setCompetitionName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [matchStatus, setMatchStatus] = useState('');
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    callAPI();
    }
  })

  const callAPI =()=>{
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    
    fetch(`https://api.supermaster.live/getmatchdata?matchId=${eventId}`, requestOptions)
    .then(async (response) => {
      let paresData = await response.json();
      setCompetitionName(paresData.match.competitionName);
      setEventName(paresData.match.eventName);
      setEventDate(paresData.match.openDate);
      setMatchStatus(paresData.match.status);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return (
    <div className='live-score'>
      <div className="heading">
        <div className="heading-text sport-name"><span className='series-name'>{competitionName}</span> <span className='seprator'>/</span> <strong>{eventName}</strong></div>
        <div className="heading-text time"><i className="fa-regular fa-clock"></i> {eventDate && (
          <>
            {new Date(eventDate).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })}
            {" at "}
            {new Date(eventDate).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </>
        )}</div></div>
      {matchStatus == 'IN_PLAY' ? <div className='scoreboard'>
        <iframe src={`https://score.supermaster.live/${evId}`} ></iframe>
      </div> : ''}
      <MobileLiveStreamTab spId={spId} evId={evId} receivedBetlistData= {receivedBetlistData}/>
    </div>
  )
}

export default Scoreboard