import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './exchanngeinfopopup.css'

function ExchanngeinfoPopup({ isOpen, handleClose }) {
    const [grulesData, setGrulesData] = useState(null);
    const [grulesAcc, setGrulesAcc ] = useState();

    const grulesAccHandle = (name) => {
        setGrulesAcc(prev => prev === name ? !prev : name);
    }

    const getData = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "whiteLabelName": "sultan444.net"
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/getGameRules`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setGrulesData(data);
            })
            .catch((error) => {
                console.log("Error fetching data:", error);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    // console.log('grulesData:', grulesData);

    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            className="fancy-popup game-rules-popup"
            dialogClassName="v-center"
        >
            <Modal.Header>
                <h2> Game Rules </h2>
            </Modal.Header>
            <Modal.Body>
                <div className='gr-accordian'>
                {grulesData?.data?.map((item, index) => (
                    <div key={index} className='gr-accordian-item'>
                        <div className={`gr-accordian-title ${grulesAcc === item.name ? 'active' : ''}`} onClick={()=> grulesAccHandle(item.name)} >{item.name} <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#212529'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg></div>
                        <div className={`gr-accordian-content ${grulesAcc === item.name ? 'active' : ''}`}>
                        <div className='content' dangerouslySetInnerHTML={{ __html: item.description }} /> </div>
                    </div>
                ))}
                </div>

                <div className='close-btn'>
                    <button onClick={handleClose} className='btn'>Close</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ExchanngeinfoPopup;
