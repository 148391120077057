import { useEffect, useRef, useCallback, useContext } from 'react';
import { PopupContext } from '../LoginPopup/LoginPopupContext';

export const useExchangeWebSocket = () => {
    const { setExchangeData } = useContext(PopupContext);
    const ws = useRef(null);
    const heartbeatInterval = useRef(null);
    const reconnectTimeout = useRef(null);
    const retryCount = useRef(0);
    const maxRetries = 5;

    const heartbeatTimeout = 30000; // 30 seconds between heartbeats

    const sendMessage = useCallback((message) => {
        if (ws.current?.readyState === WebSocket.OPEN) {
            ws.current.send(message);
        } else {
            console.log('WebSocket not ready, message queued:', message);
        }
    }, []);

    const sendHeartbeat = useCallback(() => {
        sendMessage('pong');
    }, [sendMessage]);

    const reconnectWebSocket = useCallback(() => {
        if (retryCount.current >= maxRetries) {
            console.error('Max retries reached. Stopping WebSocket reconnect attempts.');
            return;
        }

        clearTimeout(reconnectTimeout.current);

        reconnectTimeout.current = setTimeout(() => {
            console.log('Attempting to reconnect...');
            retryCount.current += 1;
            startWebSocket();
        }, 3000);
    }, []);

    const startWebSocket = useCallback(() => {
        if (ws.current && ws.current.readyState !== WebSocket.CLOSED) {
            console.log('WebSocket already initialized.');
            return;
        }

        ws.current = new WebSocket('wss://ag-api.supermaster.live/');
        // console.log(ws, 'ws.currenws.currenws.currenws.curren');
        
        ws.current.onopen = () => {
            console.log('Connected to server');
            retryCount.current = 0; // Reset retry count on successful connection
            sendMessage(JSON.stringify({ action: 'subscribe', topic: `/topic/exchange` }));
            heartbeatInterval.current = setInterval(sendHeartbeat, heartbeatTimeout);
        };

        ws.current.onmessage = (event) => {
            if (event.data === 'ping') {
                console.log('Received ping from server');
            } else {
                const { topic, marketData } = JSON.parse(event.data);
                if (marketData) {
                    const exchangeData = JSON.parse(marketData);
                    if (exchangeData && topic.startsWith('/topic/exchange')) {
                        // console.log('Updating exchange data:', exchangeData);
                        setExchangeData(exchangeData);
                    }
                }
            }
        };

        ws.current.onclose = (event) => {
            console.log('WebSocket closed:', event);
            clearInterval(heartbeatInterval.current);
            reconnectWebSocket();
        };

        ws.current.onerror = (error) => {
            console.error('WebSocket error:', error);
            clearInterval(heartbeatInterval.current);
            ws.current.close();
        };
    }, [reconnectWebSocket, sendHeartbeat, sendMessage]);

    useEffect(() => {
        startWebSocket();
        
        return () => {
            console.log('Cleaning up WebSocket...');
            if (ws.current) {
                ws.current.close();
            }
            clearInterval(heartbeatInterval.current);
            clearTimeout(reconnectTimeout.current);
        };
    }, [startWebSocket]);

    return null;
};
